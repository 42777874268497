
import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import IntroduceItem from '@/components/homepage/introduce-item.vue';
import {
  IMyHomepageBoard,
  IMyHomepageDetail,
  IMyHomepagePost,
  IMyHomepagePostAdd,
  IPost,
} from '@/types';
import { addHomepagePost, getHomepagePostList } from '@/api/myHomepagePost';
import { getInterestBoardMenuUidList, getInterestPostList } from '@/api/myHomepageInterest';
import { UserModule } from '@/store/user';

@Component({
  name: 'HomepageType1Index',
  components: {
    IntroduceItem,
  },
})
export default class extends Vue {
  @Prop({ required: true }) detail!: IMyHomepageDetail

  @Prop({ required: true }) private boardList!: IMyHomepageBoard[]

  private noticePostList: IMyHomepagePost[] = [];

  private interestPostList: IPost[] = [];

  private productPostList: IMyHomepagePost[] = [];

  private interestBoardList: any[] = [];

  private inquireForm: IMyHomepagePostAdd = {
    boardUid: '',
    title: '',
    contents: '',
    writer: '',
    parentUid: null,
    password: '',
    option1: '',
    option2: '',
    option3: '',
    option4: '',
    option5: '',
    fileUidList: [],
    postPassword: '',
  };

  get isLogined() {
    return UserModule.token;
  }

  get imageUrl() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  @Watch('boardList')
  private handleChangeBoardList() {
    this.init();
  }

  mounted() {
    this.init();
  }

  private init() {
    if (this.detail.noticeState) {
      const listQuery = {
        page: 0,
        size: 4,
        boardUid: this.boardList[0].uid,
      };
      getHomepagePostList(listQuery).then((res) => {
        this.noticePostList = res.data.content;
      });
    }
    if (this.detail.interestPostState) {
      const listQuery = {
        page: 0,
        size: 4,
      };
      getInterestPostList(this.$route.params.nickname, listQuery).then((res) => {
        this.interestPostList = res.data.content;
      });
      getInterestBoardMenuUidList().then((res) => {
        this.interestBoardList = res.data;
      });
    }
    if (this.detail.productState) {
      const listQuery = {
        page: 0,
        size: 4,
        boardUid: this.boardList[1].uid,
      };
      getHomepagePostList(listQuery).then((res) => {
        this.productPostList = res.data.content;
      });
    }
  }

  private handleClickInquireSubmit() {
    this.$validator.validate().then((valid: boolean) => {
      if (valid) {
        this.inquireForm.boardUid = this.boardList[2].uid;
        addHomepagePost(this.inquireForm).then(() => {
          alert('문의가 접수되었습니다.');
        });
      }
    });
  }

  private getInterestPostRouter(interestPost: IPost) {
    const menuFindIndex = this.interestBoardList.findIndex((b) => b.boardUid === interestPost.boardUid);
    const interestMenuUid = this.interestBoardList[menuFindIndex].menuUid;
    return {
      name: 'PostDetail',
      params: {
        menuUid: interestMenuUid,
        postUid: interestPost.uid,
      },
    };
  }
}
