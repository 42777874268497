
import { IMyHomepageBoard, IMyHomepageDetail } from '@/types';
import { Vue, Component, Prop } from 'vue-property-decorator';
import HomepageIndexType1 from '@/components/homepage/type1/index.vue';
import HomepageIndexType2 from '@/components/homepage/type2/index.vue';
import HomepageIndexType3 from '@/components/homepage/type3/index.vue';

@Component({
  name: 'HomepageIndex',
  components: {
    HomepageIndexType1,
    HomepageIndexType2,
    HomepageIndexType3,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private detail!: IMyHomepageDetail

  @Prop({ required: true }) private boardList!: IMyHomepageBoard[]
}
